<template>
  <div id="nav">
  </div>
  <router-view/>
</template>

<script>

export default {
  
  
}
</script>

<style>
</style>
