import axios from "axios";

export default class Api {
    static weather(id) {
        const url = "https://api.openweathermap.org/data/2.5/weather?id=" + id + "&appid=b1e92326fe7dadebb99f6782470fc3fd";
        return axios.post(url);
      }

    static weatherForecast(id) {
        const url = "https://api.openweathermap.org/data/2.5/forecast?id=" + id + "&&lang=hu&appid=b1e92326fe7dadebb99f6782470fc3fd";
        return axios.post(url);
    }
}
