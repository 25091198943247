<template>
  <div class="home">
    <h1>Időjárás Dachau</h1>
    <div v-for="wea in weathers" :key="wea">{{wea.dt_txt}}
    <div class="cel">{{(wea.main.temp - 273.16).toFixed(1)}}°C {{wea.weather[0].description}}</div>
    </div>
    
  </div>
</template>

<script>
import Api from "./../util/api";

export default {
  data() {
    return {
      weathers: null
    };
  },
  created() {
    let self = this;

    Api.weather(3220818)
            .then(function (response) {
              console.log(response.data);
            })
            .catch((err) => console.log(err));


        Api.weatherForecast(3220818)
            .then(function (response) {
              //console.log(response.data.list);
              self.weathers = response.data.list;
            })
            .catch((err) => console.log(err));
  },
}
</script>

<style>
.cel {
  margin: 20px;
}
</style>
